<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />     
    </div>
    <div v-else>
        <table-car-performer :driver="driver"/>
    </div>
  </div>
</template>

<script>
import tableCarPerformer from '@/views/component/Table/tableCarPerformer.vue'
export default {
    components: {
        tableCarPerformer
    },
    data() {
        return {
            driver: [],
            showPreloader: false,
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
        this.showPreloader = true
        this.$http
        .get(`drivers?filter_id=${this.$route.query.filter}`)
        .then(res => {
            this.driver = res.data
            this.showPreloader = false
            })
        },
    }
}
</script>

<style>

</style>